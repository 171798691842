import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import CategoryAdd from "./add";
import CategoryList from "./list";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import CategoryUpdate from "./update";

export default class Category extends React.Component {

    list = (props) => (
        <CategoryList {...props} getCategory={this.props.getCategory} groups={this.props.groups} categories={this.props.categories} updateCategoryStatus={this.props.updateCategoryStatus}
                      isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <CategoryAdd {...props} groups={this.props.groups} addCategory={this.props.addCategory} isRequesting={this.props.isRequestingForWhom === 'sale/category/add'}/>
    );
    update = (props) => (
        <CategoryUpdate {...props} groups={this.props.groups} addCategory={this.props.addCategory} isRequesting={this.props.isRequestingForWhom === 'sale/category/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.sale.category.url} render={() => (
                    <Redirect to={ProjectRoutes.sale.category.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.sale.category.list.url} render={this.list}/>
                <Route path={ProjectRoutes.sale.category.add.url} render={this.add}/>
                <Route path={ProjectRoutes.sale.category.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
