import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import HelperFunctions from "../../../../utils/HelperFunctions";
import common from "../../common";
import city from "../area/city";
import country from "../area/country";
import statec from "../area/state";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Customer from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class CustomerSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <Customer {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    customers: state[constants.NAME].customers,
    totalCustomerCount: state[constants.NAME].totalCustomerCount,
    orders: state[constants.NAME].orders,
    totalOrderCount: state[constants.NAME].totalOrderCount,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
    countries: HelperFunctions.getActiveForSelect(state[country.constants.NAME].countries, {name: "--Select Country--", id: ''}),
    states: HelperFunctions.getActiveForSelect(state[statec.constants.NAME].states, {name: "--Select State--", id: ''}),
    cities: HelperFunctions.getActiveForSelect(state[city.constants.NAME].cities, {name: "--Select City--", id: ''}),
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getCustomer: (queryData, isRequestingForWhom) => dispatch(actions.getCustomer(queryData, isRequestingForWhom)),
        getOrders: (queryData, isRequestingForWhom) => dispatch(actions.getOrders(queryData, isRequestingForWhom)),
        getCustomerById: (id) => dispatch(actions.getCustomerById(id)),
        updateCustomerStatus: (id, status, queryData) => dispatch(actions.updateCustomerStatus(id, status, queryData)),
        updateOrderStatus: (id, status, queryData) => dispatch(actions.updateOrderStatus(id, status, queryData)),
        updateOrderTrackingId: (id, tracking_id, callback) => dispatch(actions.updateOrderTrackingId(id, tracking_id, callback)),
        getState: (country_id) => dispatch(statec.actions.getState(country_id)),
        getCity: (country_id, state_id) => dispatch(city.actions.getCity(country_id, state_id)),
    }
};

export const CustomerContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerSmart));
export default {actionTypes, actions, reducer, constants};