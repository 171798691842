import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import HelperFunctions from "../../../../../utils/HelperFunctions";

class AddressInfo extends Component {
    constructor(props) {
        super(props);
        const addressObj = this.props.employeeAddData && this.props.employeeAddData.address ? this.props.employeeAddData.address : {};
        this.state = {
            data: {
                current_address: '',
                permanent_address: '',
                curr_pin_code: '',
                per_pin_code: '',
                country_id: '',
                state_id: '',
                city_id: '',
                ...addressObj,
            }
        }

        // call default state and cities
        if (addressObj.country_id) {
            this.props.getState(addressObj.country_id);
            if (addressObj.state_id) {
                this.props.getCity(addressObj.country_id, addressObj.state_id);
            }
        }
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    }

    countryChange = (event) => {
        this.onInputChange("country_id", event);
        this.props.getState(event.target.value);
    }

    stateChange = (event) => {
        this.onInputChange("state_id", event);
        this.props.getCity(this.state.data.country_id, event.target.value);
    }

    add = () => {
        if (!(this.props.isRequestingForWhom === "employee/address-update")) {
            this.props.updateEmployeeAddress(this.state.data);
        }
    }

    componentDidMount() {
        if (this.props.employeeAddData && this.props.employeeAddData.employee_id) {
        } else {
            this.props.changeTab(0);
        }
    }

    render() {
        const {isRequestingForWhom, countries, states, cities, employeeAddressData} = this.props;
        const {data} = this.state;
        const errors = employeeAddressData && employeeAddressData.errors ? employeeAddressData.errors : {};

        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row>
                    <Col lg="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Employee Address Info</CardTitle>
                                <Form>
                                    <Row form>
                                        <Col md={8}>
                                            <FormGroup>
                                                <Label for="current_address">Current Address</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["current_address"])} type="text" name="current_address" id="current_address"
                                                       value={data["current_address"]} onChange={this.onInputChange.bind(this, "current_address")}/>
                                                <FormFeedback>{errors["current_address"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="curr_pin_code">Pin Code</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["curr_pin_code"])} type="text" name="curr_pin_code" id="curr_pin_code"
                                                       value={data["curr_pin_code"]} onChange={this.onInputChange.bind(this, "curr_pin_code")}/>
                                                <FormFeedback>{errors["curr_pin_code"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={8}>
                                            <FormGroup>
                                                <Label for="permanent_address">Permanent Address</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["permanent_address"])} type="text" name="permanent_address" id="permanent_address"
                                                       value={data["permanent_address"]} onChange={this.onInputChange.bind(this, "permanent_address")}/>
                                                <FormFeedback>{errors["permanent_address"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="per_pin_code">Pin Code</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["per_pin_code"])} type="text" name="per_pin_code" id="per_pin_code"
                                                       value={data["per_pin_code"]} onChange={this.onInputChange.bind(this, "per_pin_code")}/>
                                                <FormFeedback>{errors["per_pin_code"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="country_id">Select Country</Label>
                                                <Input type="select" name="country_id" id="country_id" value={data["country_id"]} onChange={this.countryChange}>
                                                    {countries && countries.length > 0 && countries.map((item, index) => {
                                                        return (
                                                            <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                        )
                                                    })}
                                                </Input>
                                                <FormFeedback>{errors["country_id"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="state_id">Select State</Label>
                                                <Input type="select" name="state_id" id="state_id" value={data["state_id"]} onChange={this.stateChange}>
                                                    {states && states.length > 0 && states.map((item, index) => {
                                                        return (
                                                            <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                        )
                                                    })}
                                                </Input>
                                                <FormFeedback>{errors["state_id"]}</FormFeedback>
                                                {isRequestingForWhom === 'state/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                                    <div className="spinner-border spinner-border-sm text-primary mt-4" role="status"/>
                                                </div>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="city_id">Select City</Label>
                                                <Input type="select" name="city_id" id="city_id" value={data["city_id"]} onChange={this.onInputChange.bind(this, 'city_id')}>
                                                    {cities && cities.length > 0 && cities.map((item, index) => {
                                                        return (
                                                            <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                        )
                                                    })}
                                                </Input>
                                                <FormFeedback>{errors["city_id"]}</FormFeedback>
                                                {isRequestingForWhom === 'city/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                                    <div className="spinner-border spinner-border-sm text-primary mt-4" role="status"/>
                                                </div>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button color="primary" onClick={this.add}>
                                        {(this.props.isRequestingForWhom === "employee/address-update") && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                        {(this.props.isRequestingForWhom === "employee/address-update") ? "Save & Continue..." : "Save & Continue"}
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ReactCSSTransitionGroup>
        );
    }
}

AddressInfo.propTypes = {};

export default AddressInfo;