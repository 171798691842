import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import HelperFunctions from "../../../../../utils/HelperFunctions";

const numArr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"];
const matrtialArr = ["--Select Martial Status--", "Married", "Un Married", "Widowed", "Divorced"];

class BasicInfo extends Component {
    constructor(props) {
        super(props);
        this.isUpdate = (this.props.location.state && this.props.location.state.employee_id) ? true : false;
        const empObj = this.isUpdate ? this.props.employeeAddData : {};
        this.state = {
            data: {
                first_name: '',
                last_name: '',
                mobile: '',
                email: '',
                designation_id: '',
                department_id: '',
                martial_status: '',
                dob: '',
                work_experience: '',
                ...empObj
            }
        }
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    add = () => {
        if (!(this.props.isRequestingForWhom === "employee/add")) {
            console.log(this.state.data)
            this.props.addEmployeeBasicInfo(this.state.data);
        }
    };

    render() {
        const {isRequestingForWhom, designations, departments, employeeAddData} = this.props;
        const {data} = this.state;
        const errors = employeeAddData && employeeAddData.errors ? employeeAddData.errors : {};

        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row>
                    <Col lg="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Employee Basic Info</CardTitle>
                                <Form>
                                    <Row form>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="first_name">First Name</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["first_name"])} type="text" name="first_name" id="first_name" bsSize="sm" value={data["first_name"]}
                                                       onChange={this.onInputChange.bind(this, "first_name")}/>
                                                <FormFeedback>{errors["first_name"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="last_name">Last Name</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["last_name"])} type="text" name="last_name" id="last_name" bsSize="sm" value={data["last_name"]}
                                                       onChange={this.onInputChange.bind(this, "last_name")}/>
                                                <FormFeedback>{errors["last_name"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="gender">Select Gender</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["gender"])} type="select" name="gender" id="gender" bsSize="sm"
                                                       onChange={this.onInputChange.bind(this, "gender")} defaultValue={data.gender}>
                                                    <option>--Select Gender--</option>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </Input>
                                                <FormFeedback>{errors["gender"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="mobile">Mobile</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["mobile"])} type="text" name="mobile" id="mobile" bsSize="sm" value={data["mobile"]}
                                                       onChange={this.onInputChange.bind(this, "mobile")}/>
                                                <FormFeedback>{errors["mobile"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["email"])} type="text" name="email" id="email" bsSize="sm" value={data["email"]}
                                                       onChange={this.onInputChange.bind(this, "email")}/>
                                                <FormFeedback>{errors["email"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="department">Select Department</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["department_id"])} type="select" name="department" id="department" bsSize="sm"
                                                       onChange={this.onInputChange.bind(this, "department_id")} value={data.department_id}>
                                                    {departments && departments.length > 0 && departments.map((item, index) => {
                                                        return (
                                                            <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                        )
                                                    })}
                                                </Input>
                                                <FormFeedback>{errors["department_id"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="designation">Select Designation</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["designation_id"])} type="select" name="designation" id="designation" bsSize="sm"
                                                       onChange={this.onInputChange.bind(this, "designation_id")} value={data.designation_id}>
                                                    {designations && designations.length > 0 && designations.map((item, index) => {
                                                        return (
                                                            <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                        )
                                                    })}
                                                </Input>
                                                <FormFeedback>{errors["designation_id"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="work_experience">Work Experience(in years)</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["work_experience"])} type="select" name="work_experience" id="work_experience" bsSize="sm"
                                                       onChange={this.onInputChange.bind(this, "work_experience")} value={data.work_experience}>
                                                    {
                                                        numArr.map((value, index) => {
                                                            return (
                                                                <option key={"" + index} value={value}>{value}</option>
                                                            );
                                                        })
                                                    }
                                                </Input>
                                                <FormFeedback>{errors["work_experience"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="martial_status">Martial Status</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["martial_status"])} type="select" name="martial_status" id="martial_status" bsSize="sm"
                                                       onChange={this.onInputChange.bind(this, "martial_status")} value={data.martial_status}>
                                                    {
                                                        matrtialArr.map((value, index) => {
                                                            return (
                                                                <option key={"" + index} value={value}>{value}</option>
                                                            );
                                                        })
                                                    }
                                                </Input>
                                                <FormFeedback>{errors["martial_status"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="dob">Birth Date</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["dob"])} type="text" name="dob" id="dob" placeholder="DD-MM-YY" bsSize="sm" value={data["dob"]}
                                                       onChange={this.onInputChange.bind(this, "dob")}/>
                                                <FormFeedback>{errors["dob"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button color="primary" onClick={this.add}>
                                        {(this.props.isRequestingForWhom === "employee/add") && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                        {(this.props.isRequestingForWhom === "employee/add") ? "Save & Continue..." : "Save & Continue"}
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ReactCSSTransitionGroup>
        );
    }
}

BasicInfo.propTypes = {};

export default BasicInfo;