import * as ActionTypes from './actionTypes';

const initialState = {

};

const rootReducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.CHANGE_SCREEN:
            return {};
        default:
            return {...state}
    }
}
export default rootReducer;