import React, {Fragment, PureComponent} from 'react';
import MetisMenu from 'react-metismenu';
import LinkComponent from "./LinkComponent";
import {MastersNav} from './NavItems';

class Nav extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {

        const pathname = this.props.location.pathname;
        let activeLinkId = 1;
        for (let i = 0; i < MastersNav.length; i++) {
            if (MastersNav[i].content) {
                for (let j = 0; j < MastersNav[i].content.length; j++) {
                    if (pathname.includes(MastersNav[i].content[j].to)) {
                        activeLinkId = MastersNav[i].content[j].linkId;
                    }
                }
            } else {
                if (pathname.includes(MastersNav[i].to)) {
                    activeLinkId = MastersNav[i].linkId;
                }
            }
        }

        return (
            <Fragment>
                <h5 className="app-sidebar__heading">Master Modules</h5>
                <MetisMenu content={MastersNav} activeLinkId={activeLinkId} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"
                           LinkComponent={LinkComponent}/>
            </Fragment>
        );
    }
}

export default Nav;