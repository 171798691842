import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../../../common/pageTitle";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";

export default class GroupUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.location.state ? this.props.location.state.name : '',
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.name) {
        } else {
            this.props.history.push({pathname: ProjectRoutes.sale.group.list.url, search: HelperFunctions.getQueryStringFromURL()});
        }
    }


    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    add = () => {
        if (!this.props.isRequesting) {
            this.props.addGroup(this.state.name, this.props.location.state.id);
        }
    }

    render() {
        const {name} = this.state;
        const {isRequesting} = this.props;
        return (
            <React.Fragment>
                <PageTitle
                    heading="Jaipur Threads Groups"
                    subheading="Update group for Jaipur Threads business."
                    icon="pe-7s-keypad icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.sale.group.list.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">List Groups</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="6" md={{size: 6, offset: 3}}>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Update Group</CardTitle>
                                    <Form>
                                        <FormGroup>
                                            <Label for="name">Country</Label>
                                            <Input type="text" name="name" id="name" placeholder="pick a group..." value={name} onChange={this.onNameChange}/>
                                            <FormFeedback>Oh noes! pick a correct name for group</FormFeedback>
                                        </FormGroup>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                            {isRequesting ? "Updating..." : "Update"}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
