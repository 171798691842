import React from "react";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip} from "reactstrap";

class TrackingIDModel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            tracking_id: this.props.trackingId ? this.props.trackingId : ''
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    onInputChange = (event) => {
        this.setState({tracking_id: event.target.value})
    };

    updateTrackingID = () => {
        if ((this.props.isRequestingForWhom === "customer/order/update-order-tracking-id"))
            return;
        this.props.updateOrderTrackingId(this.props.orderId, this.state.tracking_id, this.toggle);
    };

    render() {
        return (
            <>
            <Button id={"btn1tra" + this.props.idKey} className="btn-shadow mb-2 mr-2 btn-sm" color={'warning'}
                    onClick={this.toggle}>
                <FontAwesomeIcon icon={faPaperPlane} size={"1x"}/>
                <UncontrolledTooltip placement="left" target={"btn1tra" + this.props.idKey}>{"Send Tracking #ID"}</UncontrolledTooltip>
            </Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Update Order Tracking #ID</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row form className="pt-2">
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="tracking_id">Tracking #ID</Label>
                                    <Input type="text" name="tracking_id" id="tracking_id" bsSize="sm"
                                           value={this.state.tracking_id} placeholder="#ID here..."
                                           onChange={this.onInputChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button className="" color="link" onClick={this.toggle}>Cancel</Button>
                    <Button color="primary" onClick={this.updateTrackingID}
                            disabled={this.state.tracking_id.trim() === ""}
                            className={`mb-3 ${this.state.tracking_id.trim() !== "" ? "" : "disabled"}`}>
                        {(this.props.isRequestingForWhom === "customer/order/update-order-tracking-id") &&
                        <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                        {(this.props.isRequestingForWhom === "customer/order/update-order-tracking-id") ? "Updating Tracking ID..." : "Update Track ID"}
                    </Button>
                </ModalFooter>
            </Modal>
            </>
        );
    }
}

export default TrackingIDModel;
