import {faPen, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Input, Row, Table, UncontrolledTooltip} from 'reactstrap';
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import PageTitle from "../../../../common/pageTitle";

export default class ProductTypeList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedGroupNumber: 0,
            selectedCategoryNumber: 0,
        }
    }

    componentDidMount() {
        this.props.getProductType('');
    }

    groupChange = (event) => {
        this.props.getProductType('', '');
        this.setState({selectedGroupNumber: event.target.value, selectedCategoryNumber: 0})
        this.props.getCategory(this.props.groups[event.target.value].id);
    }

    categoryChange = (event) => {
        this.setState({selectedCategoryNumber: event.target.value})
        this.props.getProductType(this.props.groups[this.state.selectedGroupNumber].id, this.props.categories[event.target.value].id);
    }

    render() {
        const {categories, isRequestingForWhom, updateProductTypeStatus, groups, productTypes} = this.props;
        const productTypesLength = productTypes ? productTypes.length : null;
        const {selectedGroupNumber, selectedCategoryNumber} = this.state;
        return (
            <React.Fragment>
                <PageTitle
                    heading="Jaipur Threads Product Types"
                    subheading="Available product Types for Jaipur Threads."
                    icon="pe-7s-albums icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.sale.productType.add.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">Add Product Type</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="card-header">
                                        Product Type List
                                        <div className="btn-actions-pane-right">
                                            <Row form>
                                                <Col>
                                                    <Input type="select" name="groups" id="groups" bsSize="sm"
                                                           onChange={this.groupChange}>
                                                        {groups && groups.length > 0 && groups.map((item, index) => {
                                                            return (
                                                                <option key={"" + index} value={index}>{item.name}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                    {isRequestingForWhom === 'sale/group/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                                        <div className="spinner-border spinner-border-sm text-primary" role="status"/>
                                                    </div>}
                                                </Col>
                                                <Col>
                                                    <Input type="select" name="category" id="category" bsSize="sm"
                                                           onChange={this.categoryChange}>
                                                        {categories && categories.length > 0 && categories.map((item, index) => {
                                                            return (
                                                                <option key={"" + index} value={index}>{item.name}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                    {isRequestingForWhom === 'sale/product-type/list' &&
                                                    <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                                        <div className="spinner-border spinner-border-sm text-primary" role="status"/>
                                                    </div>}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {productTypesLength > 0 && (
                                        <Table responsive striped className="mb-0">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {productTypes.map((item, index) => {
                                                const obj = {};
                                                if (HelperFunctions.isActive(item.status)) {
                                                    obj.icon = faThumbsDown;
                                                    obj.ttxt = "Make Inactive";
                                                    obj.color = "danger";
                                                    obj.statusToChange = "Inactive";
                                                } else {
                                                    obj.icon = faThumbsUp;
                                                    obj.ttxt = "Make Active";
                                                    obj.color = "success";
                                                    obj.statusToChange = "Active";
                                                }
                                                return (
                                                    <tr key={"" + index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.name}</td>
                                                        <td>{item.status}</td>
                                                        <td>
                                                            <Link to={{
                                                                pathname: ProjectRoutes.sale.productType.update.url,
                                                                search: HelperFunctions.getQueryStringFromURL(),
                                                                state: {
                                                                    id: item.id,
                                                                    group_id: item.group_id,
                                                                    category_id: item.category_id,
                                                                    name: item.name
                                                                }
                                                            }}>
                                                                <Button className="btn-shadow mr-2 btn-sm" color="info">
                                                                    <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                </Button>
                                                            </Link>
                                                            <Button id={"btn" + index} className="btn-shadow mr-2 btn-sm" color={obj.color}
                                                                    onClick={updateProductTypeStatus.bind(this, item.group_id, item.category_id, item.id, obj.statusToChange, item.name)}>
                                                                <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                <UncontrolledTooltip placement="left" target={"btn" + index}>
                                                                    {obj.ttxt}
                                                                </UncontrolledTooltip>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    )}
                                    {productTypesLength === 0 && (
                                        <div className="page-title-subheading d-flex justify-content-center">No Product Types found for selected group!</div>
                                    )}
                                    {isRequestingForWhom === 'sale/product-type/list' && (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {isRequestingForWhom === "sale/product-type/update-status" &&
                                <div className="d-flex justify-content-center align-items-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
