import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import common from "../../../common";
import category from "../category";
import group from "../group";
import productType from "../productType";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Product from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class ProductSmart extends React.Component {
    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <Product {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    groups: HelperFunctions.getActiveForSelect(state[group.constants.NAME].groups, {name: "--Select Group--", id: ''}),
    categories: HelperFunctions.getActiveForSelect(state[category.constants.NAME].categories, {name: "--Select Category--", id: ''}),
    productTypes: HelperFunctions.getActiveForSelect(state[productType.constants.NAME].productTypes, {name: "--Select Product Type--", id: ''}),
    products: state[constants.NAME].products,
    totalProductCount: state[constants.NAME].totalProductCount,
    productAddData: state[constants.NAME].productAddData,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getProduct: (group_id, category_id, productType_id, isEmptyFirst, queryData, isRequestingForWhom) => dispatch(actions.getProduct(group_id, category_id, productType_id, isEmptyFirst, queryData, isRequestingForWhom)),
        getCategory: (group_id, isEmptyFirst) => dispatch(category.actions.getCategory(group_id, isEmptyFirst)),
        getProductType: (group_id, category_id, isEmptyFirst) => dispatch(productType.actions.getProductType(group_id, category_id, isEmptyFirst)),
        addProduct: (data) => dispatch(actions.addProduct(data)),
        updateProductStatus: (group_id, category_id, productType_id, product_id, status, queryData) => dispatch(actions.updateProductStatus(group_id, category_id, productType_id, product_id, status, queryData)),
        updateProductTrendingStatus: (group_id, category_id, productType_id, product_id, status, queryData) => dispatch(actions.updateProductTrendingStatus(group_id, category_id, productType_id, product_id, status, queryData)),
        updateProductNewStatus: (group_id, category_id, productType_id, product_id, status, queryData) => dispatch(actions.updateProductNewStatus(group_id, category_id, productType_id, product_id, status, queryData)),
    }
};

export const ProductContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSmart));
export default {actionTypes, actions, reducer, constants};