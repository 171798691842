import React, {lazy} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {HeaderContainer} from "../header";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import AppSidebar from "../../common/appSidebar";
import Department from './components'
import common from "../../common";

class DepartmentSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
                            <Department {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    departments: state[constants.NAME].departments,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getDepartment: () => dispatch(actions.getDepartment()),
        addDepartment: (name, id) => dispatch(actions.addDepartment(name, id)),
        updateDepartmentStatus: (id, status, name) => dispatch(actions.updateDepartmentStatus(id, status, name)),
    }
};

export const DepartmentContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DepartmentSmart));
export default {actionTypes, actions, reducer, constants};