import * as ActionTypes from "./actionTypes";
import APIEndpoints from "../../../../../config/APIEndpoints";
import {Slide, toast} from "react-toastify";
import country from "../country";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {
        dispatch(country.actions.getCountry());
    }
}

export function getState(country_id, isEmptyFirst = true) {
    return (dispatch, getState) => {

        if (isEmptyFirst)
            dispatch({type: ActionTypes.GET_STATE, payload: country_id === '' ? [] : null})

        if (country_id === '')
            return;

        dispatch({
            type: ActionTypes.GET_STATE,
            payload: {isRequestingForWhom: isEmptyFirst && "state/list"},
            promise: APIEndpoints.getState(country_id),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addState(name = '', country_id = '', state_id = '') {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.ADD_STATE,
            payload: {isRequestingForWhom: "state/add"},
            promise: APIEndpoints.addState({name, country_id, state_id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateStateStatus(country_id = '', state_id = '', status = '') {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_STATE_STATUS,
            payload: {isRequestingForWhom: "state/update-status"},
            promise: APIEndpoints.updateStateStatus({country_id, state_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getState(country_id, false));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

