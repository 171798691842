import user from "../screens/user";
import * as ActionTypes from './actionTypes';
import * as constants from "./constants";

export function callOnPageLoad() {
    return (dispatch, getState) => {
        dispatch(user.actions.checkUser())
    }
}

/**
 * this action creator is called when network status changed (connected or disconnected)
 * @param networkStatus
 * @returns {Function}
 */
export function setNetworkStatus(networkStatus) {
    return (dispatch) => {
        let status;
        if (networkStatus) {
            status = true;
        } else {
            status = false;
        }
        dispatch({type: ActionTypes.SET_NETWORK_STATUS, payload: {networkStatus: status}})
    };
}

/**
 * call this action creator if you want to show a global progress in app
 * @returns {{type: string}}
 */
export function request() {
    return {type: ActionTypes.REQUEST};
}

/**
 * do nothing action creator
 * @returns {{type: string}}
 */
export function doNothing() {
    return {type: ActionTypes.DO_NOTHING};
}

/**
 * call this action creator if you want to hide a global progress in app
 * @returns {{type: string}}
 */
export function requestComplete(isWithTimeOut) {
    if (isWithTimeOut !== undefined) {
        const timeOut = setTimeout(() => {
            clearTimeout(timeOut);
            return {type: ActionTypes.REQUEST_COMPLETE};
        }, 200);
    }
    return {type: ActionTypes.REQUEST_COMPLETE};
}

/*
* action creator used for maintaing back functionality for different - different pages
* */
export function goBack() {
    return (dispatch, getState) => {

    };
}

/**
 * action creator that closes the app no matter on which screen you are
 * @returns {Function}
 */
export function exitApp() {
    return (dispatch) => {

    }
}

/**
 * action creator that called automatically whenever the device orientation is changed based on orientation height and width of the device is stored in the store and directly height and width is used in view
 * No need to calculate the height and width of the device in any view
 * @param currentOrientation
 * @returns {{type: string, payload: {currentOrientation: {orientation: *, height: *, width: *}}}}
 */
export function setCurrentOrientation() {
    return (dispatch, getState) => {
        const {currentOrientation} = getState()[constants.NAME];
        const orientation = window.innerHeight > window.innerWidth ? "PORTRAIT" : "LANDSCAPE";

        const action = {
            type: ActionTypes.SET_CURRENT_ORIENTATION,
            payload: {
                currentOrientation: {
                    orientation: orientation,
                    height: window.innerHeight,
                    width: window.innerWidth,
                }
            }
        };
        dispatch(action);
    };
}

export const setEnableBackgroundImage = enableBackgroundImage => ({
    type: ActionTypes.SET_ENABLE_BACKGROUND_IMAGE,
    enableBackgroundImage
});

export const setEnableFixedHeader = enableFixedHeader => ({
    type: ActionTypes.SET_ENABLE_FIXED_HEADER,
    enableFixedHeader
});

export const setEnableHeaderShadow = enableHeaderShadow => ({
    type: ActionTypes.SET_ENABLE_HEADER_SHADOW,
    enableHeaderShadow
});

export const setEnableSidebarShadow = enableSidebarShadow => ({
    type: ActionTypes.SET_ENABLE_SIDEBAR_SHADOW,
    enableSidebarShadow
});

export const setEnablePageTitleIcon = enablePageTitleIcon => ({
    type: ActionTypes.SET_ENABLE_PAGETITLE_ICON,
    enablePageTitleIcon
});

export const setEnablePageTitleSubheading = enablePageTitleSubheading => ({
    type: ActionTypes.SET_ENABLE_PAGETITLE_SUBHEADING,
    enablePageTitleSubheading
});

export const setEnablePageTabsAlt = enablePageTabsAlt => ({
    type: ActionTypes.SET_ENABLE_PAGE_TABS_ALT,
    enablePageTabsAlt
});

export const setEnableFixedSidebar = enableFixedSidebar => ({
    type: ActionTypes.SET_ENABLE_FIXED_SIDEBAR,
    enableFixedSidebar
});

export const setEnableClosedSidebar = enableClosedSidebar => ({
    type: ActionTypes.SET_ENABLE_CLOSED_SIDEBAR,
    enableClosedSidebar
});

export const setEnableMobileMenu = enableMobileMenu => ({
    type: ActionTypes.SET_ENABLE_MOBILE_MENU,
    enableMobileMenu
});

export const setEnableMobileMenuSmall = enableMobileMenuSmall => ({
    type: ActionTypes.SET_ENABLE_MOBILE_MENU_SMALL,
    enableMobileMenuSmall
});

export const setEnableFixedFooter = enableFixedFooter => ({
    type: ActionTypes.SET_ENABLE_FIXED_FOOTER,
    enableFixedFooter
});

export const setBackgroundColor = backgroundColor => ({
    type: ActionTypes.SET_BACKGROUND_COLOR,
    backgroundColor
});

export const setHeaderBackgroundColor = headerBackgroundColor => ({
    type: ActionTypes.SET_HEADER_BACKGROUND_COLOR,
    headerBackgroundColor
});

export const setColorScheme = colorScheme => ({
    type: ActionTypes.SET_COLOR_SCHEME,
    colorScheme
});

export const setBackgroundImageOpacity = backgroundImageOpacity => ({
    type: ActionTypes.SET_BACKGROUND_IMAGE_OPACITY,
    backgroundImageOpacity
});

export const setBackgroundImage = backgroundImage => ({
    type: ActionTypes.SET_BACKGROUND_IMAGE,
    backgroundImage
});