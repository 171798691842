import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import HelperFunctions from "../../../../utils/HelperFunctions";
import common from "../../common";
import city from "../area/city";
import country from "../area/country";
import statec from "../area/state";
import department from "../department";
import designation from "../designation";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Employee from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class EmployeeSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (

            <Employee {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    selectedTabKey: state[constants.NAME].selectedTabKey,
    employees: state[constants.NAME].employees,
    employeeAddData: state[constants.NAME].employeeAddData,
    employeeAddressData: state[constants.NAME].employeeAddressData,
    employeeEmergencyContactData: state[constants.NAME].employeeEmergencyContactData,
    employeeImageData: state[constants.NAME].employeeImageData,
    employeeDocumentsData: state[constants.NAME].employeeDocumentsData,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
    countries: HelperFunctions.getActiveForSelect(state[country.constants.NAME].countries, {name: "--Select Country--", id: ''}),
    states: HelperFunctions.getActiveForSelect(state[statec.constants.NAME].states, {name: "--Select State--", id: ''}),
    cities: HelperFunctions.getActiveForSelect(state[city.constants.NAME].cities, {name: "--Select City--", id: ''}),
    designations: HelperFunctions.getActiveForSelect(state[designation.constants.NAME].designations, {name: "--Select Designation--", id: ''}),
    departments: HelperFunctions.getActiveForSelect(state[department.constants.NAME].departments, {name: "--Select Department--", id: ''}),
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        reset: () => dispatch(actions.reset()),
        changeTab: (tabNum) => dispatch(actions.changeTab(tabNum)),
        getEmployee: () => dispatch(actions.getEmployee()),
        getEmployeeById: (id) => dispatch(actions.getEmployeeById(id)),
        addEmployeeBasicInfo: (data, id) => dispatch(actions.addEmployeeBasicInfo(data, id)),
        updateEmployeeAddress: (data) => dispatch(actions.updateEmployeeAddress(data)),
        updateEmployeeEmergencyContact: (data) => dispatch(actions.updateEmployeeEmergencyContact(data)),
        updateEmployeeImage: (data) => dispatch(actions.updateEmployeeImage(data)),
        updateEmployeeDocuments: (data) => dispatch(actions.updateEmployeeDocuments(data)),
        updateEmployeeStatus: (id, status) => dispatch(actions.updateEmployeeStatus(id, status)),
        getState: (country_id) => dispatch(statec.actions.getState(country_id)),
        getCity: (country_id, state_id) => dispatch(city.actions.getCity(country_id, state_id)),
    }
};

export const EmployeeContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeSmart));
export default {actionTypes, actions, reducer, constants};