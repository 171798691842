import React from 'react';
import user from "../user";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import {connect} from "react-redux";
import Header from "./components";
import {withRouter} from "react-router-dom";
import common from '../../common';

class HeaderSmart extends React.Component {

    render() {
        return (
            <Header {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state[common.constants.NAME].enableHeaderShadow,
    closedSmallerSidebar: state[common.constants.NAME].closedSmallerSidebar,
    headerBackgroundColor: state[common.constants.NAME].headerBackgroundColor,
    enableMobileMenuSmall: state[common.constants.NAME].enableMobileMenuSmall,
    userData: state[user.constants.NAME].userData,
});

const mapDispatchToProps = dispatch => {
    return {
        logout: (history) => dispatch(user.actions.logout(history)),
    }
};

export const HeaderContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderSmart));
export default {actionTypes, actions, reducer, constants};
