import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import StateAdd from "./add";
import StateList from "./list";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import StateUpdate from "./update";

export default class State extends React.Component {

    list = (props) => (
        <StateList {...props} getState={this.props.getState} countries={this.props.countries} states={this.props.states} updateStateStatus={this.props.updateStateStatus}
                   isRequestingForWhom={this.props.isRequestingForWhom}/>
    );

    add = (props) => (
        <StateAdd {...props} addState={this.props.addState} countries={this.props.countries} isRequesting={this.props.isRequestingForWhom === 'state/add'}/>
    );

    update = (props) => (
        <StateUpdate {...props} addState={this.props.addState} isRequesting={this.props.isRequestingForWhom === 'state/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.area.state.url} render={() => (
                    <Redirect to={ProjectRoutes.area.state.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.area.state.list.url} render={this.list}/>
                <Route path={ProjectRoutes.area.state.add.url} render={this.add}/>
                <Route path={ProjectRoutes.area.state.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
