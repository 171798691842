import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import HelperFunctions from "../../../../../utils/HelperFunctions";

class EmergencyContactInfo extends Component {
    constructor(props) {
        super(props);
        const emrAddressObj = this.props.employeeAddData && this.props.employeeAddData.emergency_contact ? this.props.employeeAddData.emergency_contact : {};
        this.state = {
            data: {
                name: '',
                mobile: '',
                ...emrAddressObj,
            }
        }
    }

    componentDidMount() {
        if (this.props.employeeAddData && this.props.employeeAddData.employee_id) {
        } else {
            this.props.changeTab(0);
        }
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    }
    add = () => {
        if (!(this.props.isRequestingForWhom === "employee/address-update")) {
            console.log(this.state.data)
            this.props.updateEmployeeEmergencyContact(this.state.data);
        }
    }

    render() {
        const {isRequestingForWhom, employeeEmergencyContactData} = this.props;
        const {data} = this.state;
        const errors = employeeEmergencyContactData && employeeEmergencyContactData.errors ? employeeEmergencyContactData.errors : {};

        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row>
                    <Col lg="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Employee Emergency Contact Info</CardTitle>
                                <Form>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="name">Name</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["name"])} type="text" name="name" id="name" value={data["name"]}
                                                       onChange={this.onInputChange.bind(this, "name")}/>
                                                <FormFeedback>{errors["name"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="mobile">Mobile</Label>
                                                <Input invalid={HelperFunctions.isNotEmpty(errors["mobile"])} type="text" name="mobile" id="mobile" value={data["mobile"]}
                                                       onChange={this.onInputChange.bind(this, "mobile")}/>
                                                <FormFeedback>{errors["mobile"]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button color="primary" onClick={this.add}>
                                        {(isRequestingForWhom === "employee/emergency-contact-update") &&
                                        <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                        {(isRequestingForWhom === "employee/emergency-contact-update") ? "Save & Continue..." : "Save & Continue"}
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ReactCSSTransitionGroup>
        );
    }
}

EmergencyContactInfo.propTypes = {};

export default EmergencyContactInfo;