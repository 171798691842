import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {HeaderContainer} from "../header";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import AppSidebar from "../../common/appSidebar";
import Designation from "./components";
import common from "../../common";

class DesignationSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
                            <Designation {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    designations: state[constants.NAME].designations,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getDesignation: () => dispatch(actions.getDesignation()),
        addDesignation: (name, id) => dispatch(actions.addDesignation(name, id)),
        updateDesignationStatus: (id, status, name) => dispatch(actions.updateDesignationStatus(id, status, name)),
    }
};

export const DesignationContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignationSmart));
export default {actionTypes, actions, reducer, constants};