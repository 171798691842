import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import HelperFunctions from "../../../../utils/HelperFunctions";
import country from "../area/country";
import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {
        dispatch(country.actions.getCountry());
    }
}

export function getCustomerById(id = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_CUSTOMER_ONE,
            payload: {isRequestingForWhom: "customer/single"},
            promise: APIEndpoints.getCustomer(id),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function getCustomer(queryData = '', isRequestingForWhom = "customer/list", isEmptyFirst = true) {
    return (dispatch, getState) => {

        if (queryData) {
            queryData = '?' + HelperFunctions.toURLEncoded(queryData);
        }

        if (isEmptyFirst)
            dispatch({type: ActionTypes.GET_CUSTOMER, payload: {customers: null}});

        dispatch({
            type: ActionTypes.GET_CUSTOMER,
            payload: {isRequestingForWhom},
            promise: APIEndpoints.getCustomer(queryData),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateCustomerStatus(customer_id = '', status = '', queryData) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_CUSTOMER_STATUS,
            payload: {isRequestingForWhom: "customer/update-status"},
            promise: APIEndpoints.updateCustomerStatus({user_id: customer_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getCustomer(queryData, undefined, false));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

export function getOrders(queryData = '', isRequestingForWhom = "customer/order", isEmptyFirst = true) {
    return (dispatch) => {

        if (queryData) {
            queryData = '?' + HelperFunctions.toURLEncoded(queryData);
        }

        if (isEmptyFirst)
            dispatch({type: ActionTypes.GET_CUSTOMER, payload: {orders: null}});

        dispatch({
            type: ActionTypes.GET_ORDER,
            payload: {isRequestingForWhom: isRequestingForWhom},
            promise: APIEndpoints.orders(queryData),
            callback: (payload) => {
                console.log(payload);
            }
        })
    }
}

export function updateOrderStatus(order_id = '', status = '', queryData) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_ORDER_STATUS,
            payload: {isRequestingForWhom: "customer/order/update-status"},
            promise: APIEndpoints.updateOrdersStatus({order_id: order_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getOrders(queryData, "customer/order/update-status", false));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

export function updateOrderTrackingId(order_id = '', tracking_id = '', callback = null) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_ORDER_TRACKING_ID,
            payload: {isRequestingForWhom: "customer/order/update-order-tracking-id"},
            promise: APIEndpoints.updateOrderTrackingId({order_id: order_id, tracking_id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
                if(callback){
                    callback();
                }
            }
        })
    }
}