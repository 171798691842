import * as ActionTypes from './actionTypes';
import sideBar6 from '../../../assets/utils/images/sidebar/city1.jpg';
/*
* @isRequesting => if true show global progress else hide it (used in root navigator)
* @isRequestingForWhom => it will be used to show progress on buttons (like no global progress but progress on individual pages) store a string here and compare the string in local pages on which you want to show the progress
* @error => @isError ==> if true error occurred while requesting else false
*           @error   ==> complete error object received from server (error message, error code etc.)
* @totalRequestCount => loader will be visible until totalRequestCount === 0
* @networkStatus => what is the network status connected or disconnected,
* @currentOrientation => represents the current orientation of the device and height and width to that particular orientation
*
* */

const initialState = {
    isRequesting: false,
    isRequestingForWhom: '',
    totalRequestCount: 0,
    setting: null,
    currentOrientation: {
        orientation: 'LANDSCAPE',
        height: window.innerHeight,
        width: window.innerWidth,
    },
    error: {
        isError: false,
        error: null
    },
    networkStatus: null,

    // admin ui
    backgroundColor: 'bg-royal sidebar-text-light',
    headerBackgroundColor: 'bg-mixed-hopes header-text-light',
    enableMobileMenuSmall: '',
    enableBackgroundImage: true,
    enableClosedSidebar: false,
    enableFixedHeader: true,
    enableHeaderShadow: true,
    enableSidebarShadow: true,
    enableFixedFooter: true,
    enableFixedSidebar: true,
    colorScheme: 'white',
    backgroundImage: sideBar6,
    backgroundImageOpacity: 'opacity-06',
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: false,
};
const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.REQUEST:
            return {
                ...state, isRequesting: true,
                isRequestingForWhom: action.payload ? action.payload.isRequestingForWhom : '',
                totalRequestCount: state.totalRequestCount + 1,
            };
        case ActionTypes.REQUEST_COMPLETE:
            return {
                ...state, isRequesting: false,
                isRequestingForWhom: '',
                totalRequestCount: state.totalRequestCount > 0 ? state.totalRequestCount - 1 : 0,
            };
        case ActionTypes.SET_CURRENT_ORIENTATION:
            return {
                ...state, currentOrientation: action.payload.currentOrientation,
            };
        case ActionTypes.SET_NETWORK_STATUS:
            return {
                ...state, networkStatus: action.payload.networkStatus,
            };
        case ActionTypes.REQUEST_FAILURE:
            return {
                ...state,
                isRequesting: false,
                isRequestingForWhom: '',
                error: {
                    isError: true,
                    error: action.payload.err
                },
            };

        case ActionTypes.SET_ENABLE_BACKGROUND_IMAGE:
            return {
                ...state,
                enableBackgroundImage: action.enableBackgroundImage
            };

        case ActionTypes.SET_ENABLE_FIXED_HEADER:
            return {
                ...state,
                enableFixedHeader: action.enableFixedHeader
            };

        case ActionTypes.SET_ENABLE_HEADER_SHADOW:
            return {
                ...state,
                enableHeaderShadow: action.enableHeaderShadow
            };

        case ActionTypes.SET_ENABLE_SIDEBAR_SHADOW:
            return {
                ...state,
                enableSidebarShadow: action.enableSidebarShadow
            };

        case ActionTypes.SET_ENABLE_PAGETITLE_ICON:
            return {
                ...state,
                enablePageTitleIcon: action.enablePageTitleIcon
            };

        case ActionTypes.SET_ENABLE_PAGETITLE_SUBHEADING:
            return {
                ...state,
                enablePageTitleSubheading: action.enablePageTitleSubheading
            };

        case ActionTypes.SET_ENABLE_PAGE_TABS_ALT:
            return {
                ...state,
                enablePageTabsAlt: action.enablePageTabsAlt
            };

        case ActionTypes.SET_ENABLE_FIXED_SIDEBAR:
            return {
                ...state,
                enableFixedSidebar: action.enableFixedSidebar
            };

        case ActionTypes.SET_ENABLE_MOBILE_MENU:
            return {
                ...state,
                enableMobileMenu: action.enableMobileMenu
            };

        case ActionTypes.SET_ENABLE_MOBILE_MENU_SMALL:
            return {
                ...state,
                enableMobileMenuSmall: action.enableMobileMenuSmall
            };

        case ActionTypes.SET_ENABLE_CLOSED_SIDEBAR:
            return {
                ...state,
                enableClosedSidebar: action.enableClosedSidebar
            };

        case ActionTypes.SET_ENABLE_FIXED_FOOTER:
            return {
                ...state,
                enableFixedFooter: action.enableFixedFooter
            };

        case ActionTypes.SET_BACKGROUND_COLOR:
            return {
                ...state,
                backgroundColor: action.backgroundColor
            };

        case ActionTypes.SET_HEADER_BACKGROUND_COLOR:
            return {
                ...state,
                headerBackgroundColor: action.headerBackgroundColor
            };

        case ActionTypes.SET_COLOR_SCHEME:
            return {
                ...state,
                colorScheme: action.colorScheme
            };

        case ActionTypes.SET_BACKGROUND_IMAGE:
            return {
                ...state,
                backgroundImage: action.backgroundImage
            };

        case ActionTypes.SET_BACKGROUND_IMAGE_OPACITY:
            return {
                ...state,
                backgroundImageOpacity: action.backgroundImageOpacity
            };
        default:
            return {...state}
    }
};
export default commonReducer;