import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Col, FormFeedback, FormGroup, FormText, Label, Row} from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Slide, toast} from "react-toastify";

class PhotoUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }

    componentDidMount() {
        if (this.props.employeeAddData && this.props.employeeAddData.employee_id) {
        } else {
            this.props.changeTab(0);
        }
    }


    handleUploadImage = (ev) => {
        ev.preventDefault();
        console.log(this.uploadInput);
        const files = this.uploadInput.files;
        if (files && files.length === 1 && ['image/png', 'image/jpeg', 'image/jpg'].includes(files[0].type) && (files[0].size < 500000)) {
            const data = new FormData();
            data.append('file', files[0]);
            console.log(files[0], 'files', data)
            this.props.updateEmployeeImage(data);
        } else {
            this.toastId = toast("select atleast one image with 'image/png', 'image/jpeg', 'image/jpg' extension with max 100KB size", {
                transition: Slide,
                closeButton: true,
                autoClose: 5000,
                position: toast.POSITION.TOP_CENTER,
                type: 'error',
                hideProgressBar: true,
            })
        }
    }

    render() {

        const {isRequestingForWhom, employeeImageData, employeeAddData} = this.props;
        const {data} = this.state;
        const errors = employeeImageData && employeeImageData.errors ? employeeImageData.errors : {};

        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row>
                    <Col lg="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Employee Photo</CardTitle>
                                <form onSubmit={this.handleUploadImage}>
                                    <FormGroup row>
                                        <Label for="exampleFile" sm={3}>Profile Photo</Label>
                                        <Col sm={3}>
                                            <img width={64} className="rounded-circle" src={"" + employeeAddData.base_url + employeeAddData.image_url} alt=""/>
                                        </Col>
                                        <Col sm={6}>
                                            <input ref={(ref) => {
                                                this.uploadInput = ref;
                                            }} type="file"/>
                                            <FormFeedback>{errors["file_input"]}</FormFeedback>
                                            <FormText color="muted">Please pick a .png/.jpg image that will display on jaipur threads dashboard.</FormText>
                                        </Col>
                                    </FormGroup>
                                    <Button color="primary" onClick={this.add}>
                                        {(isRequestingForWhom === "employee/image-update") &&
                                        <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                        {(isRequestingForWhom === "employee/image-update") ? "Save & Continue..." : "Save & Continue"}
                                    </Button>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ReactCSSTransitionGroup>
        );
    }
}

PhotoUpload.propTypes = {};

export default PhotoUpload;