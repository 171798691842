import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import CountryAdd from "./add";
import CountryList from "./list";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import CountryUpdate from "./update";

export default class Country extends React.Component {

    list = (props) => (
        <CountryList {...props} getCountry={this.props.getCountry} countries={this.props.countries} updateCountryStatus={this.props.updateCountryStatus} isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <CountryAdd {...props} addCountry={this.props.addCountry} countryAddData={this.props.countryAddData} isRequesting={this.props.isRequestingForWhom === 'country/add'}/>
    );
    update = (props) => (
        <CountryUpdate {...props} addCountry={this.props.addCountry} countryAddData={this.props.countryAddData} isRequesting={this.props.isRequestingForWhom === 'country/add'}/>
    );


    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.area.country.url} render={() => (
                    <Redirect to={ProjectRoutes.area.country.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.area.country.list.url} render={this.list}/>
                <Route path={ProjectRoutes.area.country.add.url} render={this.add}/>
                <Route path={ProjectRoutes.area.country.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
