import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import DesignationAdd from "./add";
import DesignationList from "./list";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import DesignationUpdate from "./update";
import CountryList from "../../area/country/components/list";
import CountryAdd from "../../area/country/components/add";
import CountryUpdate from "../../area/country/components/update";

export default class Designation extends React.Component {

    list = (props) => (
        <DesignationList {...props} getDesignation={this.props.getDesignation} designations={this.props.designations} updateDesignationStatus={this.props.updateDesignationStatus} isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <DesignationAdd {...props} addDesignation={this.props.addDesignation} isRequesting={this.props.isRequestingForWhom === 'designation/add'}/>
    );
    update = (props) => (
        <DesignationUpdate {...props} addDesignation={this.props.addDesignation} isRequesting={this.props.isRequestingForWhom === 'designation/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.designation.url} render={() => (
                    <Redirect to={ProjectRoutes.designation.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.designation.list.url} render={this.list}/>
                <Route path={ProjectRoutes.designation.add.url} render={this.add}/>
                <Route path={ProjectRoutes.designation.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
