import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import ProductTypesAdd from "./add";
import ProductTypesList from "./list";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import ProductTypesUpdate from "./update";

export default class ProductTypes extends React.Component {

    list = (props) => (
        <ProductTypesList {...props} getProductType={this.props.getProductType} getCategory={this.props.getCategory} categories={this.props.categories} groups={this.props.groups}
                          productTypes={this.props.productTypes}
                          updateProductTypeStatus={this.props.updateProductTypeStatus}
                          isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <ProductTypesAdd {...props} getCategory={this.props.getCategory} categories={this.props.categories} groups={this.props.groups} addProductType={this.props.addProductType}
                         isRequesting={this.props.isRequestingForWhom === 'sale/product-type/add'}/>
    );
    update = (props) => (
        <ProductTypesUpdate {...props} getCategory={this.props.getCategory} categories={this.props.categories} groups={this.props.groups} addProductType={this.props.addProductType}
                            isRequesting={this.props.isRequestingForWhom === 'sale/product-type/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.sale.productType.url} render={() => (
                    <Redirect to={ProjectRoutes.sale.productType.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.sale.productType.list.url} render={this.list}/>
                <Route path={ProjectRoutes.sale.productType.add.url} render={this.add}/>
                <Route path={ProjectRoutes.sale.productType.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
