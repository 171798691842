import * as ActionTypes from "./actionTypes";

const initialState = {
    countries: null,
    countryAddData: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_COUNTRY:
            return {...state, countries: action.payload};
        case ActionTypes.ADD_COUNTRY:
            return {...state, countryAddData: action.payload};
        default:
            return {...state}
    }
};
export default reducer;