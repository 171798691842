import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import DepartmentAdd from "./add";
import DepartmentList from "./list";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import DepartmentUpdate from "./update";

export default class Department extends React.Component {

    list = (props) => (
        <DepartmentList {...props} getDepartment={this.props.getDepartment} departments={this.props.departments} updateDepartmentStatus={this.props.updateDepartmentStatus}
                        isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <DepartmentAdd {...props} addDepartment={this.props.addDepartment} isRequesting={this.props.isRequestingForWhom === 'department/add'}/>
    );
    update = (props) => (
        <DepartmentUpdate {...props} addDepartment={this.props.addDepartment} isRequesting={this.props.isRequestingForWhom === 'department/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.department.url} render={() => (
                    <Redirect to={ProjectRoutes.department.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.department.list.url} render={this.list}/>
                <Route path={ProjectRoutes.department.add.url} render={this.add}/>
                <Route path={ProjectRoutes.department.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
