import * as ActionTypes from "./actionTypes";

const initialState = {
    customers: null,
    totalCustomerCount: 0,
    orders: null,
    totalOrderCount: 0
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_CUSTOMER:
            return {...state, ...action.payload};
        case ActionTypes.GET_ORDER:
            return {...state, ...action.payload};
        default:
            return {...state}
    }
};
export default reducer;