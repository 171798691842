import React from "react";
import {faFileInvoice} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Modal, UncontrolledTooltip} from "reactstrap";
import Invoice from "../../../../../commons/components/invoice/index";

class InvoiceButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return (
            <>
            <Button id={"btninvoice" + this.props.idKey} className="btn-shadow mb-2 mr-2 btn-sm" color={'secondary'}
                    onClick={this.toggle}>
                <FontAwesomeIcon icon={faFileInvoice} size={"1x"}/>
                <UncontrolledTooltip placement="left" target={"btninvoice" + this.props.idKey}>{"Download Invoice"}</UncontrolledTooltip>
            </Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <Invoice toggle={this.toggle} order={this.props.order}/>
            </Modal>
            </>
        );
    }
}

export default InvoiceButton;
