import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actions from "./actions";
import * as actionTypes from "./actionTypes";
import Dashboard from "./components";
import * as constants from "./constants";
import reducer from "./reducer";

class DashboardSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <Dashboard {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    stats: state[constants.NAME].stats,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
    }
};

export const DashboardContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardSmart));
export default {actionTypes, actions, reducer, constants};