/**
 * This is the Entry point for application and it uses <Provider> from
 * react-redux to provide state as props to application components
 *
 * RootNavigatorWithState is a common that maintains the complete page navigation in whole app
 * every page (from first page to last page) will be embedded in this navigator with the help of RouteConfigs(utils/RouteConfig)
 */

import React from 'react';
import {Provider} from 'react-redux';
import './config/ReactronConfig'
import {RootNavigatorContainer} from "./redux/modules/navigator/rootNavigator";
import store from "./redux/store";
import './assets/base.css';
import './assets/custom.css';

const App = (props) => {
    return (
        <Provider store={store}>
            <RootNavigatorContainer/>
        </Provider>
    );
};
export default App;