// import GModal from '@commons/components/gModal';
import React from 'react';
import {connect} from "react-redux";
import * as constants from "../constants";

class Loader extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps.error !== this.props.error) {
            if (nextProps.error.isError) {
                switch (nextProps.error.error.code) {
                    case 403:
                        // HelperFunctions.redirectToLoginSSO();
                        // this.props.storeUserInfo();
                        break;
                    default:
                    // alert('in Error Loader', nextProps.error.error.message)
                }
            }
        }
    }

    render() {
        const loader = this.props.isRequesting ? (
            <div className="main-loader">
                <div className="loader-outer">
                    <div className="loader">
                        <div className="cricle-loading">
                            <div className="spinner-icon"></div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
        return loader;
    }
}

const mapStateToProps = state => {
        const commonState = state[constants.NAME];
        return {
            isRequesting: commonState.isRequesting,
            isRequestingForWhom: commonState.isRequestingForWhom,
            error: commonState.error,
            currentOrientation: commonState.currentOrientation,
            totalRequestCount: commonState.totalRequestCount,
        }
    }
;

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);