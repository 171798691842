import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import GroupAdd from "./add";
import GroupList from "./list";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import GroupUpdate from "./update";

export default class Group extends React.Component {

    list = (props) => (
        <GroupList {...props} getGroup={this.props.getGroup} groups={this.props.groups} updateGroupStatus={this.props.updateGroupStatus} isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <GroupAdd {...props} addGroup={this.props.addGroup} isRequesting={this.props.isRequestingForWhom === 'sale/group/add'}/>
    );
    update = (props) => (
        <GroupUpdate {...props} addGroup={this.props.addGroup} isRequesting={this.props.isRequestingForWhom === 'sale/group/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.sale.group.url} render={() => (
                    <Redirect to={ProjectRoutes.sale.group.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.sale.group.list.url} render={this.list}/>
                <Route path={ProjectRoutes.sale.group.add.url} render={this.add}/>
                <Route path={ProjectRoutes.sale.group.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
