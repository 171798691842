import * as ActionTypes from "./actionTypes";
import country from "../country";
import state from "../state";
import APIEndpoints from "../../../../../config/APIEndpoints";
import {Slide, toast} from "react-toastify";


export function callOnPageLoad(current_game_url = '') {
    return (dispatch) => {
        dispatch(country.actions.getCountry());
    }
}

export function getCity(country_id, state_id, isEmptyFirst = true) {
    return (dispatch) => {

        if (isEmptyFirst)
            dispatch({type: ActionTypes.GET_CITY, payload: (country_id === '' || state_id === '') ? [] : null})

        if (country_id === '' && state_id === '')
            dispatch(state.actions.getState(''));

        if (country_id === '' || state_id === '')
            return;

        dispatch({
            type: ActionTypes.GET_CITY,
            payload: {isRequestingForWhom: isEmptyFirst && "city/list"},
            promise: APIEndpoints.getCity(country_id, state_id),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addCity(name = '', country_id = '', state_id = '', city_id = '') {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.ADD_CITY,
            payload: {isRequestingForWhom: "city/add"},
            promise: APIEndpoints.addCity({name, country_id, state_id, city_id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateCityStatus(country_id = '', state_id = '', city_id = '', status = '') {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_CITY_STATUS,
            payload: {isRequestingForWhom: "city/update-status"},
            promise: APIEndpoints.updateCityStatus({country_id, state_id, city_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getCity(country_id, state_id, false));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
