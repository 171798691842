import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../../config/APIEndpoints";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import group from "../group";
import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch) => {
        dispatch(group.actions.getGroup(false));
    }
}

export function getProduct(group_id, category_id, productType_id, isEmptyFirst = true, queryData = '', isRequestingForWhom = "product/list") {
    return (dispatch) => {

        let payloadObj = {};
        if (isEmptyFirst) {
            dispatch({type: ActionTypes.GET_PRODUCT, payload: {products: null}});
            payloadObj = {isRequestingForWhom};
        }

        if (queryData) {
            queryData = '?' + HelperFunctions.toURLEncoded(queryData);
        }

        dispatch({
            type: ActionTypes.GET_PRODUCT,
            payload: payloadObj,
            promise: APIEndpoints.getProduct(group_id, category_id, productType_id, queryData),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addProduct(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.ADD_PRODUCT,
            payload: {isRequestingForWhom: "product/add"},
            promise: APIEndpoints.addProduct(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateProductStatus(group_id, category_id, productType_id, product_id = '', status = '', queryData) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_PRODUCT_STATUS,
            payload: {isRequestingForWhom: "product/update-status"},
            promise: APIEndpoints.updateProductStatus({product_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getProduct(group_id, category_id, productType_id, false, queryData));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

export function updateProductTrendingStatus(group_id, category_id, productType_id, product_id = '', status = '', queryData) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_PRODUCT_STATUS,
            payload: {isRequestingForWhom: "product/update-status"},
            promise: APIEndpoints.updateProductTrendingStatus({product_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getProduct(group_id, category_id, productType_id, false, queryData));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

export function updateProductNewStatus(group_id, category_id, productType_id, product_id = '', status = '', queryData) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_PRODUCT_STATUS,
            payload: {isRequestingForWhom: "product/update-status"},
            promise: APIEndpoints.updateProductNewStatus({product_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getProduct(group_id, category_id, productType_id, false, queryData));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

