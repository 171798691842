import APIEndpoints from "../../../../config/APIEndpoints";
import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {
        dispatch(getDashStats());
    }
}

export function getDashStats() {
    return (dispatch, getState) => {

        dispatch({
            type: ActionTypes.GET_DASH_STATS,
            payload: {isRequestingForWhom: 'dashboard/stats'},
            promise: APIEndpoints.dashStats(),
        })
    }
}