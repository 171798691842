import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {HeaderContainer} from "../../header";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import AppSidebar from "../../../common/appSidebar";
import CountryAdd from "./components/add";
import CountryList from "./components/list";
import Country from "./components";
import common from "../../../common";
import ContentLoader from "../../../../../commons/components/contentLoader";

class CountrySmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
                            <Country {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    countries: state[constants.NAME].countries,
    countryAddData: state[constants.NAME].countryAddData,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getCountry: () => dispatch(actions.getCountry()),
        addCountry: (name, id) => dispatch(actions.addCountry(name, id)),
        updateCountryStatus: (id, status) => dispatch(actions.updateCountryStatus(id, status)),
    }
};

export const CountryContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CountrySmart));
export default {actionTypes, actions, reducer, constants, CountryList, CountryAdd};