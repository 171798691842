import * as ActionTypes from "./actionTypes";

const initialState = {
    states: [],
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_STATE:
            return {...state, states: action.payload};
        default:
            return {...state}
    }
};
export default reducer;