import * as ActionTypes from "./actionTypes";

const initialState = {
    products: [],
    productAddData: {},
    totalProductCount: 0
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT:
            return {...state, ...action.payload};
        case ActionTypes.ADD_PRODUCT:
        case ActionTypes.GET_PRODUCT_ONE:
            return {...state, productAddData: action.payload};
        default:
            return {...state}
    }
};
export default reducer;