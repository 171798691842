import React, {Fragment} from "react";
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import EmployeeAdd from "./add";
import EmployeeList from "./list";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class Employee extends React.Component {

    list = (props) => (
        <EmployeeList {...props} getEmployee={this.props.getEmployee} employees={this.props.employees}
                      updateEmployeeStatus={this.props.updateEmployeeStatus}
                      isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <EmployeeAdd {...props} {...this.props}/>
    );
    update = (props) => (
        <EmployeeAdd {...props} {...this.props}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.employee.url} render={() => (
                    <Redirect to={ProjectRoutes.employee.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.employee.list.url} render={this.list}/>
                <Route path={ProjectRoutes.employee.add.url} render={this.add}/>
                <Route path={ProjectRoutes.employee.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
