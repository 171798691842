import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import routes from "../../../../../config/routes";
import AppSidebar from "../../../common/appSidebar";
import ScrollToTop from '../../../common/scrollToTop';
import {HeaderContainer} from "../../../screens/header";

function RouteWithSubRoutes(route) {

    return (
        <React.Fragment>
            <Route
                exact={route.exact}
                path={route.path}
                render={route.render ? route.render : props => (
                    // pass the sub-routes down to keep nesting
                    <route.component {...props} routes={route.routes}/>
                )}
            />
            {
                route.routes && route.routes.map((value, index) => {
                    return (
                        <RouteWithSubRoutes {...value}/>
                    )

                })
            }
        </React.Fragment>
    );
}

export default class RootNavigator extends React.PureComponent {

    render() {

        const {userData} = this.props;

        const isLoggedIn = userData && userData.employee_id ? true : false;

        return (
            <React.Fragment>
                <Router basename={''}>
                    <ScrollToTop>
                        <React.Fragment>
                            {isLoggedIn && <HeaderContainer/>}
                            <div className="app-main">
                                {isLoggedIn && <AppSidebar/>}
                                <div className="app-main__outer">
                                    <div className="app-main__inner">
                                        <Switch>
                                            {routes.map((route, i) => (
                                                <RouteWithSubRoutes key={i} {...route} />
                                            ))}
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </ScrollToTop>
                    <ToastContainer/>
                </Router>
            </React.Fragment>

        );
    }

}