import React, {PureComponent} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";

export default class ImageCrop extends PureComponent {
    state = {
        src: null,
        crop: {
            width: 150,
            height: 150
        },
    };

    componentDidMount() {
        this.uploadInput.click();
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            const file = e.target.files[0];
            console.log(file);
            reader.addEventListener('load', () => {
                    this.setState({src: reader.result})

                    // this.props.getCroppedImage(reader.result, file);
                }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                blob.lastModifiedDate = new Date();
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({croppedImageUrl: this.fileUrl, blob: blob});
            }, 'image/jpeg');
        });
    }

    cropImage = () => {
        if (this.state.croppedImageUrl) {
            this.props.getCroppedImage(this.state.croppedImageUrl);
        }
    };

    render() {
        const {crop, croppedImageUrl, src} = this.state;
        return (
            <React.Fragment>
                <input ref={(ref) => this.uploadInput = ref} type="file" onChange={this.onSelectFile} hidden/>

                {src && (
                    <Modal isOpen={true}>
                        <ModalBody>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.props.hideImageCrop}>Cancel</Button>
                            <Button color="primary" onClick={this.cropImage}>Crop Image</Button>
                        </ModalFooter>
                    </Modal>
                )}
                {/*{croppedImageUrl && (
                    <img alt="Crop" style={{maxWidth: '100%'}} src={croppedImageUrl}/>
                )}*/}
            </React.Fragment>
        );
    }
}
