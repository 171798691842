import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {HeaderContainer} from "../../header";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import AppSidebar from "../../../common/appSidebar";
import Category from "./components";
import common from "../../../common";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import group from "../group";

class CategorySmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
                            <Category {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    categories: state[constants.NAME].categories,
    groups: HelperFunctions.getActiveForSelect(state[group.constants.NAME].groups, {name: "--Select Group--", id: ''}),
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getCategory: (group_id) => dispatch(actions.getCategory(group_id)),
        addCategory: (name, group_id, id) => dispatch(actions.addCategory(name, group_id, id)),
        updateCategoryStatus: (group_id, id, status, name) => dispatch(actions.updateCategoryStatus(group_id, id, status, name)),
    }
};

export const CategoryContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CategorySmart));
export default {actionTypes, actions, reducer, constants};