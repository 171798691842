import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import ProductAdd from "./add";
import ProductList from "./list";

export default class Product extends React.Component {

    list = (props) => (
        <ProductList {...props} getProduct={this.props.getProduct}
                     getCategory={this.props.getCategory} getProductType={this.props.getProductType}
                     groups={this.props.groups} categories={this.props.categories}
                     productTypes={this.props.productTypes} products={this.props.products} totalProductCount={this.props.totalProductCount}
                     updateProductStatus={this.props.updateProductStatus}
                     updateProductTrendingStatus={this.props.updateProductTrendingStatus}
                     updateProductNewStatus={this.props.updateProductNewStatus}
                     isRequestingForWhom={this.props.isRequestingForWhom}/>
    );

    add = (props) => (
        <ProductAdd {...props} getCategory={this.props.getCategory} getProductType={this.props.getProductType} addProduct={this.props.addProduct} groups={this.props.groups}
                    categories={this.props.categories} productTypes={this.props.productTypes}
                    productAddData={this.props.productAddData}
                    isRequesting={this.props.isRequestingForWhom === 'product/add'}/>
    );
    update = (props) => (
        <ProductAdd {...props} getCategory={this.props.getCategory} getProductType={this.props.getProductType} addProduct={this.props.addProduct} groups={this.props.groups}
                    categories={this.props.categories} productTypes={this.props.productTypes}
                    productAddData={this.props.productAddData}
                    isRequesting={this.props.isRequestingForWhom === 'product/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.sale.product.url} render={() => (
                    <Redirect to={ProjectRoutes.sale.product.list.url + HelperFunctions.getQueryStringFromURL()}/>
                )}/>
                <Route path={ProjectRoutes.sale.product.list.url} render={this.list}/>
                <Route path={ProjectRoutes.sale.product.add.url} render={this.add}/>
                <Route path={ProjectRoutes.sale.product.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
