import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import User from "./components";
import common from "../../common";

class UserSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.history);
    }

    render() {
        return (
            <React.Fragment>
                <User {...this.props}/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    userData: state[constants.NAME].userData,
    forgotPassData: state[constants.NAME].forgotPassData,
    matchOTPData: state[constants.NAME].matchOTPData,
    newPasswordData: state[constants.NAME].newPasswordData,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (history) => dispatch(actions.callOnPageLoad(history)),
        login: (email_mobile, password, routerHistory) => dispatch(actions.login(email_mobile, password, routerHistory)),
        forgotPassword: (email_mobile, routerHistory) => dispatch(actions.forgotPassword(email_mobile, routerHistory)),
        matchOTP: (email_mobile, otp, routerHistory) => dispatch(actions.matchOTP(email_mobile, otp, routerHistory)),
        newPassword: (email_mobile, password, routerHistory) => dispatch(actions.newPassword(email_mobile, password, routerHistory)),
    }
};

export const UserContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSmart));
export default {actionTypes, actions, reducer, constants};