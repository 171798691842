import * as ActionTypes from "./actionTypes";
import APIEndpoints from "../../../../../config/APIEndpoints";
import {Slide, toast} from "react-toastify";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {

    }
}

export function getCountry() {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_COUNTRY,
            payload: {isRequestingForWhom: "country/list"},
            promise: APIEndpoints.getCountry(),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}


export function addCountry(name = '', id = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_COUNTRY,
            payload: {isRequestingForWhom: "country/add"},
            promise: APIEndpoints.addCountry({name, id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateCountryStatus(id = '', status = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_COUNTRY_STATUS,
            payload: {isRequestingForWhom: "country/update-status"},
            promise: APIEndpoints.updateCountryStatus({id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getCountry());
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

