import React, {Component} from 'react';
import images from "../../../assets/images";
import HelperFunctions from "../../../utils/HelperFunctions";

class ErrorContainer extends Component {
    render() {
        return (
            <div className="page-not-found" style={{position:'fixed', zIndex:999999}}>
                <div className="not-found-mid">
                    <div className="container-not-found">
                        <div className="header-top">
                            <div className="logo-top"><a href="javascript:void(0);"><img src={images.ppLogo} alt=""/></a></div>
                        </div>
                        <div className="mid-card-not-found">
                            <div className="inner-red-card">
                                <h2>RED CARD</h2>
                                <p>We are unable to process your request at the moment. Please try again later.</p>
                                <div className="btn-home"><a className="skyblue-btn" href={'/'+ HelperFunctions.getQueryStringFromURL()}>Return to the game</a>
                                </div>
                            </div>
                        </div>
                        <div className="red-card-pic"><img src={images.redCard} alt=""/></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorContainer;