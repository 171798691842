import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../../config/APIEndpoints";
import group from "../group";
import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {
        dispatch(group.actions.getGroup());
    }
}

export function getCategory(group_id, isEmptyFirst = true) {
    return (dispatch, getState) => {
        let payloadObj = {};
        if (isEmptyFirst) {
            dispatch({type: ActionTypes.GET_CATEGORY, payload:null});
            payloadObj = {isRequestingForWhom: "sale/category/list"};
        }

        // if (group_id === '')
        //     return;

        dispatch({
            type: ActionTypes.GET_CATEGORY,
            payload: payloadObj,
            promise: APIEndpoints.getCategory(group_id),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch({type: ActionTypes.GET_CATEGORY, payload: []})
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addCategory(name = '', group_id = '', id = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_CATEGORY,
            payload: {isRequestingForWhom: "sale/category/add"},
            promise: APIEndpoints.addCategory({name, group_id, id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateCategoryStatus(group_id = '', id = '', status = '', name = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_CATEGORY_STATUS,
            payload: {isRequestingForWhom: "sale/category/update-status"},
            promise: APIEndpoints.updateCategoryStatus({id, status, group_id, name}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getCategory(group_id, false));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
