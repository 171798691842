import React from 'react';
import Stats from "./stats";
import PageTitle from "../../../common/pageTitle";

export default class Dashboard extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <PageTitle
                    heading="Jaipur Threads Dashboard"
                    subheading="Below are the latest stats in Jaipur Threads sytem."
                    icon="pe-7s-graph1 icon-gradient bg-ripe-malin"
                />
                <Stats {...this.props}/>
            </React.Fragment>
        )
    }
}