export const MastersNav = [
    {
        icon: 'pe-7s-graph2',
        label: 'Dashboard',
        to: '/dashboard',
        linkId: 1
    },
    {
        icon: 'pe-7s-map-marker',
        label: 'Area Module',
        linkId: 2,
        content: [
            {
                label: 'Country',
                to: '/area/country',
                linkId: 3
            },
            {
                label: 'State',
                to: '/area/state',
                linkId: 4

            },
            {
                label: 'City',
                to: '/area/city',
                linkId: 5
            }
        ],
    },
    {
        icon: 'pe-7s-id',
        label: 'Employee Module',
        linkId: 6,
        content: [
            {
                icon: 'pe-7s-display1',
                label: 'Designation',
                to: '/designation',
                linkId: 7
            },
            {
                icon: 'pe-7s-display2',
                label: 'Department',
                to: '/department',
                linkId: 8
            },
            {
                icon: 'pe-7s-id',
                label: 'Employee',
                to: '/employee',
                linkId: 9
            },
        ]
    },
    {
        icon: 'pe-7s-keypad',
        label: 'Product Groups',
        to: '/sale/group',
        linkId: 10
    },
    {
        icon: 'pe-7s-server',
        label: 'Product Categories',
        to: '/sale/category',
        linkId: 11
    },
    {
        icon: 'pe-7s-albums',
        label: 'Product Types',
        to: '/sale/product-type',
        linkId: 12
    },
    {
        icon: 'pe-7s-cart',
        label: 'Products',
        to: '/sale/product',
        linkId: 13
    },
    {
        icon: 'pe-7s-users',
        label: 'Customer Module',
        linkId: 14,
        content: [
            {
                icon: 'pe-7s-display1',
                label: 'Customers',
                to: '/customer',
                linkId: 15
            },
            {
                icon: 'pe-7s-display2',
                label: 'Orders',
                to: '/customer/order',
                linkId: 16
            },
        ]
    },
];