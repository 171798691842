import React, {Fragment, PureComponent} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Link} from "react-router-dom";
import {Col, Row} from 'reactstrap';
import {Area, AreaChart, Bar, BarChart, ResponsiveContainer} from 'recharts';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

const data = [
    {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
    {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
    {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
    {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
    {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
    {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
    {name: 'Page C', uv: 2000, pv: 6800, amt: 2290},
    {name: 'Page D', uv: 4780, pv: 7908, amt: 2000},
    {name: 'Page E', uv: 2890, pv: 9800, amt: 2181},
    {name: 'Page F', uv: 1390, pv: 3800, amt: 1500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
];

const data2 = [
    {name: 'Page A', uv: 5400, pv: 5240, amt: 1240},
    {name: 'Page B', uv: 7300, pv: 4139, amt: 3221},
    {name: 'Page C', uv: 8200, pv: 7980, amt: 5229},
    {name: 'Page D', uv: 6278, pv: 4390, amt: 3200},
    {name: 'Page E', uv: 3189, pv: 7480, amt: 6218},
    {name: 'Page D', uv: 9478, pv: 6790, amt: 2200},
    {name: 'Page E', uv: 1289, pv: 1980, amt: 7218},
    {name: 'Page F', uv: 3139, pv: 2380, amt: 5150},
    {name: 'Page G', uv: 5349, pv: 3430, amt: 3210},
];

class Stats extends PureComponent {

    getToRender = (key) => {
        const {stats} = this.props;
        if (stats === null)
            return <div className="spinner-grow spinner-grow-sm mt-4 mb-4 text-primary" role="status"/>
        else {
            return stats[key] ? HelperFunctions.convertNumberToKs(stats[key]) : 0;
        }
    };

    render() {

        const {stats} = this.props;

        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div>
                        <Row>
                            <Col md="4">
                                <Link to={ProjectRoutes.employee.list.url + HelperFunctions.getQueryStringFromURL()} className="cursor-pointer text-decoration-none">
                                    <div className="card mb-3 widget-chart">
                                        <div className="icon-wrapper rounded-circle">
                                            <div className="icon-wrapper-bg bg-primary"/>
                                            <i className="pe-7s-id text-primary"/>
                                        </div>
                                        <div className="widget-numbers">
                                            {this.getToRender('employee_count')}
                                        </div>
                                        <div className="widget-subheading">
                                            Total Employees
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col md="4">
                                <Link to={ProjectRoutes.sale.product.list.url + HelperFunctions.getQueryStringFromURL()} className="cursor-pointer text-decoration-none">
                                    <div className="card mb-3 widget-chart">
                                        <div className="icon-wrapper rounded-circle">
                                            <div className="icon-wrapper-bg bg-arielle-smile"/>
                                            <i className="pe-7s-cart text-alternate"/>
                                        </div>
                                        <div className="widget-numbers">
                                            {this.getToRender('product_count')}
                                        </div>
                                        <div className="widget-subheading">
                                            Total Products
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col md="4">
                                <Link to={ProjectRoutes.customer.list.url + HelperFunctions.getQueryStringFromURL()} className="cursor-pointer text-decoration-none">
                                    <div className="card mb-3 widget-chart">
                                        <div className="widget-chart-content">
                                            <div className="icon-wrapper rounded-circle">
                                                <div className="icon-wrapper-bg bg-love-kiss"/>
                                                <i className="pe-7s-users text-warning"/>
                                            </div>
                                            <div className="widget-numbers">
                                                {this.getToRender('user_count')}
                                            </div>
                                            <div className="widget-subheading">
                                                Total User Profiles
                                            </div>
                                            {/*<div className="widget-description text-success">
                                            <span className="pr-1">175.5%</span>
                                            <FontAwesomeIcon icon={faArrowRight}/>
                                        </div>*/}
                                        </div>
                                        {stats && (
                                            <div className="widget-chart-wrapper">
                                                <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                                    <AreaChart data={data}
                                                               margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                                        <Area type='monotoneX' dataKey='uv' stroke='#3f6ad8' fill='#3f6ad8'/>
                                                    </AreaChart>
                                                </ResponsiveContainer>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                        <div className="divider mt-0" style={{marginBottom: '30px'}}/>
                        <Row>
                            <Col md="4">
                                <Link to={ProjectRoutes.customer.order.url + HelperFunctions.getQueryStringFromURL()} className="cursor-pointer text-decoration-none">
                                    <div className="card mb-3 widget-chart">
                                        <div className="widget-chart-content">
                                            <div className="icon-wrapper rounded-circle">
                                                <div className="icon-wrapper-bg bg-asteroid"/>
                                                <i className="pe-7s-graph3 text-aqua"/>
                                            </div>
                                            <div className="widget-numbers">
                                                {this.getToRender('orders_total')}
                                            </div>
                                            <div className="widget-subheading">
                                                Net sales this month
                                            </div>
                                            {/*<div className="widget-description text-success">
                                            <span className="pr-1">175.5%</span>
                                            <FontAwesomeIcon icon={faArrowRight}/>
                                        </div>*/}
                                        </div>
                                        {stats && (
                                            <div className="widget-chart-wrapper">
                                                <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                                    <AreaChart data={data}
                                                               margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                                        <Area type='monotoneX' dataKey='uv' stroke='#fd7e14' fill='#ffb87d'/>
                                                    </AreaChart>
                                                </ResponsiveContainer>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            </Col>
                            <Col md="4">
                                <Link to={ProjectRoutes.customer.order.url + HelperFunctions.getQueryStringFromURL()} className="cursor-pointer text-decoration-none">
                                    <div className="card mb-3 widget-chart">
                                        <div className="widget-chart-content">
                                            <div className="icon-wrapper rounded-circle">
                                                <div className="icon-wrapper-bg bg-success"/>
                                                <i className="pe-7s-like2 text-success"/>
                                            </div>
                                            <div className="widget-numbers">
                                                {this.getToRender('order_completed_count')}
                                            </div>
                                            <div className="widget-subheading">
                                                Completed Orders
                                            </div>
                                            {/*<div className="widget-description text-warning">
                                            <FontAwesomeIcon icon={faAngleUp}/>
                                            <span className="pl-1">54.1%</span>
                                        </div>*/}
                                        </div>
                                        {stats && (
                                            <div className="widget-chart-wrapper">
                                                <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                                    <BarChart data={data}>
                                                        <Bar dataKey='uv' fill='#009e51' stroke='#007a3d' strokeWidth={2}/>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            </Col>
                            <Col md="4">
                                <Link to={ProjectRoutes.customer.order.url + HelperFunctions.getQueryStringFromURL()} className="cursor-pointer text-decoration-none">
                                    <div className="card mb-3 widget-chart">
                                        <div className="widget-chart-content">
                                            <div className="icon-wrapper rounded-circle">
                                                <div className="icon-wrapper-bg bg-info"/>
                                                <i className="pe-7s-shopbag text-info"/>
                                            </div>
                                            <div className="widget-numbers">
                                                {this.getToRender('order_ongoing_count')}
                                            </div>
                                            <div className="widget-subheading">
                                                Pending/Ongoing Orders
                                            </div>
                                            {/*<div className="widget-description text-info">
                                            <FontAwesomeIcon icon={faArrowRight}/>
                                            <span className="pl-1">175.5%</span>
                                        </div>*/}
                                        </div>
                                        {stats && (
                                            <div className="widget-chart-wrapper">
                                                <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                                    <AreaChart data={data}
                                                               margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                                        <Area type='stepAfter' dataKey='uv' stroke='#3aabff' fill='#78C3FB'/>
                                                    </AreaChart>
                                                </ResponsiveContainer>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

export default Stats;
