import * as ActionTypes from "./actionTypes";

const initialState = {
    designations: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_DESIGNATION:
            return {...state, designations: action.payload};
        default:
            return {...state}
    }
};
export default reducer;