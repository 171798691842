import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {HeaderContainer} from "../../header";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import AppSidebar from "../../../common/appSidebar";
import City from "./components";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import country from "../country";
import statec from "../state";
import common from "../../../common";

class CitySmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (

                            <City {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    countries: HelperFunctions.getActiveForSelect(state[country.constants.NAME].countries, {name: "--Select Country--", id: ''}),
    states: HelperFunctions.getActiveForSelect(state[statec.constants.NAME].states, {name: "--Select State--", id: ''}),
    cities: state[constants.NAME].cities,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getState: (country_id) => dispatch(statec.actions.getState(country_id)),
        getCity: (country_id, state_id) => dispatch(actions.getCity(country_id, state_id)),
        addCity: (name, country_id, state_id, city_id) => dispatch(actions.addCity(name, country_id, state_id, city_id)),
        updateCityStatus: (country_id, state_id, city_id, status) => dispatch(actions.updateCityStatus(country_id, state_id, city_id, status)),
    }
};

export const CityContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CitySmart));
export default {actionTypes, actions, reducer, constants};