import React from 'react';
import {Button} from 'reactstrap';
import Tabs from 'react-responsive-tabs';
import PageTitle from "../../../common/pageTitle";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import BasicInfo from "./basicInfo";
import AddressInfo from "./addressInfo";
import EmergencyContactInfo from "./emergencyContactInfo";
import PhotoUpload from "./photoUpload";
import DocumentUpload from "./documentUpload";

export default class EmployeeAdd extends React.Component {

    constructor(props) {
        super(props);
        this.isUpdate = (this.props.location.state && this.props.location.state.employee_id) ? true : false;
    }

    componentDidMount() {
        this.props.reset();
        if (this.isUpdate) {
            this.props.getEmployeeById(this.props.location.state.employee_id);
        }
    }

    getTabs = () => {
        const tabsContent = [
            {
                title: 'Basic Info',
                content: <BasicInfo {...this.props}/>
            },
            {
                title: 'Address Info',
                content: <AddressInfo {...this.props}/>
            },
            {
                title: 'Emergency Contact Info',
                content: <EmergencyContactInfo {...this.props}/>
            },
            {
                title: 'Photo',
                content: <PhotoUpload {...this.props}/>
            },
            {
                title: 'Document Upload',
                content: <DocumentUpload {...this.props}/>
            },

        ];
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }

    render() {
        const isShow = this.isUpdate ? (this.props.employeeAddData && this.props.employeeAddData.employee_id) ? true : false : true;
        return (
            <React.Fragment>
                <PageTitle
                    heading="Jaipur Threads Employee"
                    subheading={this.isUpdate ? "Update a Employee for Jaipur Threads business." :"Add a new Employee for Jaipur Threads business."}
                    icon="pe-7s-users icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.employee.list.url + HelperFunctions.getQueryStringFromURL()}>
                            <Button className="btn-shadow mr-3" color="dark">List Employee</Button>
                        </Link>
                    )}
                />
                {isShow ? (
                    <Tabs onChange={this.props.changeTab} selectedTabKey={this.props.selectedTabKey}
                          tabsWrapperClass="body-tabs body-tabs-layout" transform={true} showInkBar={true}
                          items={this.getTabs()}/>
                ) : (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
