import PropTypes from "prop-types";
import React, {Component} from 'react';

class ContentLoader extends Component {
    render() {
        return (
            <div className={`loading-wrapper ${this.props.loaderType}`}>
                <div className="page-overlay">
                    <div className="spinner-grow text-primary" role="status"></div>
                </div>
            </div>
        );
    }
}

ContentLoader.propTypes = {
    loaderType: PropTypes.string,
};

ContentLoader.defaultProps = {
    loaderType: "",
};

export default ContentLoader;