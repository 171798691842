import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Col, FormGroup, FormText, Label, Row} from "reactstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Slide, toast} from "react-toastify";

class DocumentUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }

    componentDidMount() {
        if (this.props.employeeAddData && this.props.employeeAddData.employee_id) {
        } else {
            this.props.changeTab(0);
        }
    }

    showToast = (message) => {
        this.toastId = toast("select atleast " + message + " image with 'image/png', 'image/jpeg', 'image/jpg' extension with max 100KB size", {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: toast.POSITION.TOP_CENTER,
            type: 'error',
            hideProgressBar: true,
        })
    }

    handleUploadImage = (ev) => {
        ev.preventDefault();
        const files1 = this.uploadInput1.files;
        if (files1 && files1.length === 1 && ['image/png', 'image/jpeg', 'image/jpg'].includes(files1[0].type) && (files1[0].size < 500000)) {
            const data = new FormData();
            data.append('file1', files1[0]);

            const files2 = this.uploadInput2.files;
            if (files2 && files2.length === 1 && ['image/png', 'image/jpeg', 'image/jpg'].includes(files2[0].type) && (files2[0].size < 500000)) {
                data.append('file2', files2[0]);

                const files3 = this.uploadInput3.files;
                if (files3 && files3.length === 1 && ['image/png', 'image/jpeg', 'image/jpg'].includes(files3[0].type) && (files3[0].size < 500000)) {
                    data.append('file3', files3[0]);

                    this.props.updateEmployeeDocuments(data);

                } else {
                    this.showToast("pvc")
                }
            } else {
                this.showToast("Pan card")
            }
        } else {
            this.showToast("adhar");
        }
    }

    render() {

        const {isRequestingForWhom, employeeImageData, employeeAddData} = this.props;
        const {data} = this.state;
        const errors = employeeImageData && employeeImageData.errors ? employeeImageData.errors : {};

        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row>
                    <Col lg="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Employee Document Upload</CardTitle>
                                <form onSubmit={this.handleUploadImage}>
                                    <FormGroup row>
                                        <Label for="exampleFile" sm={3}>Aadhar Card Photo</Label>
                                        <Col sm={3}>
                                            <img width={64} src={employeeAddData.base_url + (employeeAddData.documents ? employeeAddData.documents.aadhar_url : "")} alt=""/>
                                        </Col>
                                        <Col sm={6}>
                                            <input ref={(ref) => {
                                                this.uploadInput1 = ref;
                                            }} type="file"/>
                                            <FormText color="muted">Please pick a .png/.jpg image front face of your aadhar card</FormText>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleFile" sm={3}>PAN Card Photo</Label>
                                        <Col sm={3}>
                                            <img width={64} src={employeeAddData.base_url + (employeeAddData.documents ? employeeAddData.documents.pencard_url : "")} alt=""/>
                                        </Col>
                                        <Col sm={6}>
                                            <input ref={(ref) => {
                                                this.uploadInput2 = ref;
                                            }} type="file"/>
                                            <FormText color="muted">Please pick a .png/.jpg image front face of your pan card</FormText>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleFile" sm={3}>Police Verification Photo</Label>
                                        <Col sm={3}>
                                            <img width={64} src={employeeAddData.base_url + (employeeAddData.documents ? employeeAddData.documents.pvc_url : "")} alt=""/>
                                        </Col>
                                        <Col sm={6}>
                                            <input ref={(ref) => {
                                                this.uploadInput3 = ref;
                                            }} type="file"/>
                                            <FormText color="muted">Please pick a .png/.jpg image front face of your police verification certificate.</FormText>
                                        </Col>
                                    </FormGroup>
                                    <Button color="primary" onClick={this.add}>
                                        {(isRequestingForWhom === "employee/documents-update") &&
                                        <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                        {(isRequestingForWhom === "employee/documents-update") ? "Saving..." : "Save"}
                                    </Button>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ReactCSSTransitionGroup>
        );
    }
}

DocumentUpload.propTypes = {};

export default DocumentUpload;