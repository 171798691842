import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Button, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import images from "../../../../../assets/images";

export default class OTP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: ''
        }
    }

    matchOTP = () => {
        if (!this.props.isRequesting) {
            const email_mobile = this.props.location.state ? this.props.location.state.email_mobile : '';
            this.props.matchOTP(email_mobile, this.state.otp, this.props.history);
        }
    }

    changeHandler = event => {
        this.setState({
            otp: event.target.value
        });
    }

    render() {
        const {matchOTPData, isRequesting} = this.props;
        let isInvalidOTP = null;
        if (matchOTPData && matchOTPData.errors) {
            if (matchOTPData.errors.otp) {
                isInvalidOTP = matchOTPData.errors.otp;
            }
        }
        return (
            <div className="mx-auto app-login-box col-md-8">
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div className="d-flex justify-content-center mb-3">
                        <img src={images.logo} width={200} alt="...logo"/>
                    </div>
                </ReactCSSTransitionGroup>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div className="modal-dialog w-100 mx-auto">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="h5 modal-title text-center">
                                    <h4 className="mt-2">
                                        <div>Enter OTP Received</div>
                                        <span>Use OTP received on mail/mobile to recover password!</span>
                                    </h4>
                                </div>
                                <Form>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faEdit}/></InputGroupText>
                                            </InputGroupAddon>
                                            <Input invalid={isInvalidOTP} type="text" name="otp" id="otp"
                                                   placeholder="Enter OTP here..." value={this.state.otp}
                                                   onChange={this.changeHandler}/>
                                            <FormFeedback>{isInvalidOTP}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                            </div>
                            <div className="modal-footer clearfix">
                                <div className="float-left">
                                    <a href="#" className="btn-lg btn btn-link">Request OTP Again</a>
                                </div>
                                <div className="float-right">
                                    <Button color="primary" size={"lg"} onClick={this.matchOTP}>
                                        {isRequesting &&
                                        <span className="spinner-grow spinner-grow-sm mr-1" role="status"
                                              aria-hidden="true"/>}
                                        Submit OTP
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-white opacity-8 mt-3">Copyright © PrintfWorld 2019</div>
                </ReactCSSTransitionGroup>
            </div>
        )
    }
}
