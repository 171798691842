import React, {Component} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import * as constants from "../constants";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

class PageTitle extends Component {

    render() {
        let {
            enablePageTitleIcon,
            enablePageTitleSubheading,
            heading,
            icon,
            subheading,
            actionChildren
        } = this.props;

        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName="HeaderAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div
                                className={cx("page-title-icon", {'d-none': !enablePageTitleIcon})}>
                                <i className={icon}/>
                            </div>
                            <div>
                                {heading}
                                <div
                                    className={cx("page-title-subheading", {'d-none': !enablePageTitleSubheading})}>
                                    {subheading}
                                </div>
                            </div>
                        </div>
                        <div className="page-title-actions">
                            {actionChildren}
                        </div>
                    </div>
                </div>
            </ReactCSSTransitionGroup>
        );
    }
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state[constants.NAME].enablePageTitleIcon,
    enablePageTitleSubheading: state[constants.NAME].enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);