import * as ActionTypes from "./actionTypes";
import APIEndpoints from "../../../../config/APIEndpoints";
import {Slide, toast} from "react-toastify";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {

    }
}

export function getDepartment() {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_DEPARTMENT,
            payload: {isRequestingForWhom: "department/list"},
            promise: APIEndpoints.getDepartment(),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}


export function addDepartment(name = '', id = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_DEPARTMENT,
            payload: {isRequestingForWhom: "department/add"},
            promise: APIEndpoints.addDepartment({name, id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateDepartmentStatus(id = '', status = '', name = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_DEPARTMENT_STATUS,
            payload: {isRequestingForWhom: "department/update-status"},
            promise: APIEndpoints.updateDepartmentStatus({id, status, name}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getDepartment());
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
